import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

window.addEventListener('load', (): void => {
  new Swiper('.swiper', {
    modules: [Navigation, Pagination],

    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    grabCursor: true,

    breakpoints: {
      676: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },

    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },

    pagination: {
      el: '.swiper-bullets',
      clickable: true,
      bulletElement: 'button',
      bulletClass: 'swiper-bullet',
      bulletActiveClass: 'active',
    },
  });
});
